/* .App{
  text-align: center;
  margin-top: 50px;

}

.table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 18px;
}

th,
td {
  border: 1px solid #aca8a8;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #81887d;
}
h2{
  text-align: center;
  color: #000;
}
.logo{
  margin-top:0%;
} */

.MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
}